<template>
    <div class="main-container">

        <script type="application/javascript" src="https://bossanova.uk/jspreadsheet/v4/jexcel.js"></script>
        <script type="application/javascript" src="https://jsuites.net/v4/jsuites.js"></script>
        <link rel="stylesheet" href="https://bossanova.uk/jspreadsheet/v4/jexcel.css" type="text/css" />
        <link rel="stylesheet" href="https://jsuites.net/v4/jsuites.css" type="text/css" />

        <div>
            <div id="spreadsheet"></div>

            <mdb-container class="text-right">
                <mdb-btn id="backBtn" @click="moveBack" v-if="showButtonBack">목록</mdb-btn>
                <mdb-btn id="issueBtn" outline="primary" @click="saveLogBook" v-if="showButton1" :disabled="disableButton1">발급</mdb-btn>
                <mdb-btn @click="makeExcel" v-if="showButton2" :disabled="disableButton2">다운로드</mdb-btn>
            </mdb-container>
        </div>

        <mdb-modal size="lg" :show="airFieldModal" @close="closeAirFieldModal">
            <mdb-modal-header>
                <mdb-modal-title>비행장소 (An Airfield)</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <mdb-datatable-2
                    v-model="airFieldList"
                    class="text-center"
                    striped
                    bordered
                    hover
                    noFoundMessage="데이터가 없습니다."/>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn color="secondary" @click.native="closeAirFieldModal">Close</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>

        <mdb-modal size="lg" :show="purposeModal" @close="closePurposeModal">
            <mdb-modal-header>
                <mdb-modal-title>비행목적</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <!-- 전체 선택/해제 버튼 -->
                <div>
                    <input type="checkbox" id="selectAll" v-model="checkSelectAll" @change="selectAllCheckboxes" class="custom-checkbox"/>
                    <label for="selectAll" class="checkbox-label">
                        <span class="select-all-text">전체 선택</span>
                    </label>
                </div>

                <div class="checkbox-container" v-for="(checkbox, index) in checkboxes" :key="index">
                    <input type="checkbox" :id="'checkbox' + index" v-model="checkbox.value" class="custom-checkbox"/>
                    <label :for="'checkbox' + index" class="checkbox-label">{{ checkbox.label }}</label>
                </div>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn color="secondary" @click.native="closePurposeModal">Close</mdb-btn>
                <mdb-btn color="primary" @click.native="getCheckedValues">Apply</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>

        <mdb-modal size="lg" :show="instructorModal" @close="closeInstructorModal">
            <mdb-modal-header>
                <mdb-modal-title>지도조종자 (Instructor)</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <mdb-datatable-2
                    v-model="instructorList"
                    class="text-center"
                    striped
                    bordered
                    hover
                    noFoundMessage="데이터가 없습니다."/>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn color="secondary" @click.native="closeInstructorModal">Close</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
        <div class="progress-container">
            <Progress :isProgress="isProgress"/>
        </div>
    </div>
</template>
<style scoped>

.checkbox-container {
  display: inline-block; /* 각 체크박스를 가로로 배치 */
  margin-right: 15px; /* 체크박스 간 간격 설정 */
}
.checkbox-label {
  margin-left: 5px; /* 체크박스와 라벨 사이의 간격 설정 */  
  color: #333; /* 텍스트 색상 설정 */
}

.custom-checkbox:checked,
.custom-checkbox:not(:checked) {
  position: static;
  pointer-events: auto;
  opacity: 1;
}
.modal-lg {
    max-width: 1000px;
}

.modal-cover {
    background: rgba(0,0,0,.5);
    position: fixed;
    z-index: 48;
}

.progress-container {
  position: fixed;
  top: 50%;
  left: 50%;  
}

.progress-icon {    
    position: fixed;
    z-index: 49;
}

</style>
<script>
import jspreadsheet from 'jspreadsheet-ce';
import exceljs from 'exceljs';
import axios from "@/axios";
import { mdbContainer, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbDatatable2 } from "mdbvue";
import airfield_list from "@/assets/data/columns/airfield_list";
import instructor_list from "@/assets/data/columns/instructor_list";
import Progress from "@/components/Progress";

export default {
    name: "기체 비행기록부",
    components: {
        mdbContainer,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
        mdbBtn,
        mdbDatatable2,
        Progress
    },
    data() {
        return {
            constants: {
                TOP_LINE: 2,
                LEFT_LINE: 4,
                RIGHT_LINE: 8,
                BOTTOM_LINE: 16,
                ALL_LINE: 30
            },
            token: this.$store.state.token.access_token,            
            selectedDate: this.$route.params.selectedDate,
            logList: [],
            logBookList: [],
            mySpreadsheet: null,
            workbook: null,
            tabs:[],
            spreadData:[],
            sheets: [],
            machineList: [],
            DATA_START_ROW: 9,
            PAGE_PER_DATA: 16,
            pageMaxCol: 17,
            airFieldList: {
                columns: airfield_list,
                rows: []
            },            
            instructorList: {
                columns: instructor_list,
                rows: []
            },
            airField: [],
            instructor: [],
            airFieldModal: false,
            airFieldModalIndex: 0,
            instructorModal: false,
            instructorModalIndex: 0,
            isModal: false,
            isProgress: false,
            selectedRow: null,
            selectedCol: null,
            selectedTab: null,
            showButtonBack: true,
            showButton1: true,
            showButton2: false,
            disableButtonBack: true,
            disableButton1: false,
            disableButton2: false,

            checkboxes: [],
            checkSelectAll: false,
            checkedValues: '',
            isChecked: 'false',
            purposeModal: false,
            flightPurpose: [],

            
            logBookInfo: this.$route.params.logBookInfo,
            source: this.$route.params.source,
        }
    },
    created() {
        window.airFieldSelect = this.airFieldSelect;
        window.instructorSelect = this.instructorSelect;
    },
    mounted() {        
        this.init();
    },
    methods: {
      async init() {

        const asyncFunctions = [this.getAirFieldInfo(), this.getFlightPurpose(), this.getInstructorInfo()];
               
        try {
            await Promise.all(asyncFunctions);                

        } catch (error) {
            console.error('에러 발생: ' + error);
        }        

        if (this.source === "issue") {
            try {
                await this.getRecord();
                await this.setMachineList();
                await this.setJSpreadsheet();
            
                this.setMerge();                
            } catch (error) {
                console.error(error.message);
                this.$router.push({name: 'logbookissue'})
            }
        }

        if (this.source === "reissue") {
            try {
                await this.getLogBook();
                await this.setMachineList();
                await this.setJSpreadsheet();
            
                this.setData();


                for(var i=0; i<this.machineList.length; i++) {
                    var sheet = document.getElementById('spreadsheet').jexcel[i];
                    var machineType = this.logBookList.filter(item =>  {
                        const result = item.droneType === this.machineList[i];                        
                        return result;
                    });
                    
                    for (var j=0; j < 17; j++) {
                        for (var k=0; k < machineType[0].data.length*2+10; k++) {
                            sheet.setReadOnly([j,k], true);
                        }
                    }
                }

                this.showButton1 = false;
                this.showButton2 = true;

            } catch (error) {
                console.error(error.message);
                this.$router.push({name: 'logbooklist'})
            }
        }
      },      
      async getAirFieldInfo() {
        const self = this;
        const config = {
            method: 'get',
            url: '/certificate/airfield',
            headers: {
                'Content-Type': 'application/json'                
            }
        };

        return axios(config)
            .then((res) => {
                this.airField = res.data;                

                this.airField.forEach((data)=> {
                    self.airFieldModalIndex++;
                    data['orderNo'] = self.airFieldModalIndex;                        
                    data['action'] = '<button class="btn-detail" onclick="airFieldSelect(\'' + data.uuid + '\')">선택</button>'

                    self.airFieldList.rows.push(data)
                })   

            });
      },    
      async getFlightPurpose() {
            const config = {
                method: 'get',
                url: '/certificate/purpose',
                headers: {
                    'Content-Type': 'application/json'                
                }
            };

            return axios(config)
                .then((res) => {
                    this.flightPurpose = res.data;                    

                    this.flightPurpose.forEach(data => {
                        this.checkboxes.push({
                            label: data.purpose,
                            value: false,
                        })
                    });                    

                });
        },
        async getInstructorInfo() {
            const self = this;
            const config = {
                method: 'get',
                url: '/certificate/instructor',
                headers: {
                    'Content-Type': 'application/json'                
                }
            };

            return axios(config)
                .then((res) => {
                    this.instructor = res.data;                    

                    this.instructor.forEach((data)=> {
                        self.instructorModalIndex++;
                        data['orderNo'] = self.instructorModalIndex;                        
                        data['action'] = '<button class="btn-detail" onclick="instructorSelect(\'' + data.uuid + '\')">선택</button>'

                        self.instructorList.rows.push(data)
                    });   
                });
        },
        airFieldSelect(value) {
            const self = this;
            const selData = self.airFieldList.rows.find((element) => element.uuid === value)
            
            
            this.airFieldModal = false;
            this.isModal = false;

            let sheet = document.getElementById('spreadsheet').jexcel[this.selectedTab];
            sheet.setValueFromCoords(1, this.selectedRow, selData.airfield, true);  
        },        
        instructorSelect(value) {
            const self = this;
            const selData = self.instructorList.rows.find((element) => element.uuid === value)


            this.instructorModal = false;
            this.isModal = false;


            let sheet = document.getElementById('spreadsheet').jexcel[this.selectedTab];
            sheet.setValueFromCoords(14, this.selectedRow, selData.name, true);  
            sheet.setValueFromCoords(15, this.selectedRow, selData.no, true);           
        },
        getCheckedValues() {
            const checkedItems = this.checkboxes.filter(checkbox => checkbox.value).map(checkbox => checkbox.label);
            this.checkedValues = checkedItems.join(', ');

            this.purposeModal = false;
            this.isModal = false;

            let sheet = document.getElementById('spreadsheet').jexcel[this.selectedTab];
            sheet.setValueFromCoords(10, this.selectedRow, this.checkedValues, true);
        },
        closeAirFieldModal() {
            this.airFieldModal = false;
            this.isModal = false;
        },
        closeInstructorModal() {
            this.instructorModal = false;
            this.isModal = false;
        },
        closePurposeModal() {
            this.purposeModal = false;
            this.isModal = false;
        },
        async getRecord() {
            this.isProgress = true;
            const self = this;
            const config = {
                method: 'get',
                url: `/logrecord/loglist?date=${this.selectedDate}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${self.token}`
                }

            };

            try {
                await axios(config)
                    .then((res) => {
                        self.logList = res.data;
                        console.log('Error Count: ' + self.logList[0].errorCount);
                        console.log('Less then a minute Count: ' + self.logList[0].timeCount);
                        this.getRecentData();
                    })
            } catch (error) {                
                this.isProgress = false;

                if (error.response.data.message) {
                    var errorArray = error.response.data.message.split(',');
                    var errorType = errorArray[0];
                    var errorCount = errorArray[1];
                    var timeCount = errorArray[2];

                    console.log('Error Count: ' + errorCount);
                    console.log('Less then a minute Count: ' + timeCount);

                    if (errorType === 'noType') {
                        self.$swal.fire({title: '조회 실패', text: '드론의 형식이 지정되었는지 확인해 주세요.', icon: 'error'});            
                    } else if (errorType === 'noData') {
                        self.$swal.fire({title: '조회 실패', text: '해당 날짜에 데이터가 없습니다.', icon: 'error'});
                    }

                    throw new Error(error.response.data.message);
                } else {
                    self.$swal.fire({title: '조회 실패', text: '드론의 형식이 지정되었는지 확인해 주세요.', icon: 'error'});
                }
            }  
        },
        async getLogBook() {
            this.isProgress = true;
            const self = this;
            const config = {
                method: 'get',
                url: `/logrecord/logbook?date=${this.logBookInfo.flightDate}&index=${this.logBookInfo.index}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${self.token}`
                }
            };

            try {
                await axios(config)
                    .then((res) => {
                        self.logBookList = res.data;
                        this.isProgress = false;
                    })
            } catch (error) {
                this.isProgress = false;
                self.$swal.fire({title: '조회 실패', text: '기체비행기록부 조회 오류가 발생했습니다.', icon: 'error'});            
                throw new Error('기체비행기록부 조회 오류가 발생했습니다.');            
            }  
        },
        async getRecentData() {
            const self = this;
            const config = {
                method: 'get',
                url: `/logrecord/recent?date=${this.selectedDate}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${self.token}`
                }

            };
            
            await axios(config)
                .then((res) => {
                    self.logBookList = res.data;

                    for(let i=0; i<this.machineList.length; i++) {
                
                        var machineType = this.logList.filter(item =>  {
                            const result = item.droneType === this.machineList[i];                            
                            return result;
                        });

                        var sheet = document.getElementById('spreadsheet').jexcel[i];
                        
                        for(let j=10; j<machineType[0].data.length*2+10; j+=2) {

                            if(this.logBookList.length !== 0 )
                            {
                                for(let index = 0; index < this.logBookList.length; index++) {
                                    let issuedStartDate = new Date(this.logBookList[index].startTime);
                                    let issuedStartHour = issuedStartDate.getHours().toString().padStart(2,'0');                        
                                    let issuedStartMinute = issuedStartDate.getMinutes().toString().padStart(2,'0');
                                    let formattedStartTime = `${issuedStartHour}:${issuedStartMinute}`;

                                    let issuedEndDate = new Date(this.logBookList[index].endTime);
                                    let issuedEndHour = issuedEndDate.getHours().toString().padStart(2,'0');
                                    let issuedEndMinute = issuedEndDate.getMinutes().toString().padStart(2,'0');
                                    let formattedEndTime = `${issuedEndHour}:${issuedEndMinute}`;

                                    let issuedUserName = this.logBookList[index].studentName;
                                    let issuedDroneType = this.logBookList[index].droneType;

                                    let startTime = sheet.getValueFromCoords(2, j);
                                    let endTime = sheet.getValueFromCoords(3, j);
                                    let userName = sheet.getValueFromCoords(11, j);
                                    let droneType = sheet.getValueFromCoords(9, 1);

                                    if(formattedStartTime === startTime && formattedEndTime === endTime &&
                                        issuedUserName === userName && issuedDroneType == droneType)
                                        {
                                            sheet.setValueFromCoords(1, j, this.logBookList[index].airField);
                                            sheet.setValueFromCoords(10, j, this.logBookList[index].flightPurpose);
                                            sheet.setValueFromCoords(14, j, this.logBookList[index].instructorName);
                                            sheet.setValueFromCoords(15, j, this.logBookList[index].instructorNo);
                                        }


                                }
                            }
                        }
                    }

                    this.disableButton1 = false;
                    this.isProgress = false;
                })
            
        },
        async setMachineList() {   
            if (this.source === "issue") {            
                this.logList.forEach(item => {
                    const type = item.droneType;
                    if(!this.machineList.includes(type)) {
                        this.machineList.push(type);
                    }
                });
            } else if(this.source ==="reissue") {
                this.logBookList.sort((a,b) => {
                    const orderA = parseInt(a.droneOrder, 10);
                    const orderB = parseInt(b.droneOrder, 10);

                    return orderA - orderB;
                });

                this.logBookList.forEach(item => {
                    const type = item.droneType;
                    if(!this.machineList.includes(type)) {
                        this.machineList.push(type);
                    }
                });
            }

            for(let i=0;i<this.machineList.length;i++) {
                let data = [
                    ['기체 비행기록부(Flight Logbook)', '', '', '', '', '', '', '', '', '', '', '', '*날씨:              *일출:              *일몰:       ', '', '', '', ''],
                    ['기체(機體) 정보', '', '종      류 :', '', '', '', '', '형           식 :', '', '', '', '', '신고번호 :', '', '', '', ''],
                    ['', '', '자체중량 :', '', '', '', '', '최대이륙중량 :', '', '', '', '', '인정기관 :', '', '', '', '(서명)'],
                    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
                    ['①연월일', '②비행장소', '이륙 시각', '착륙 시각', '비행시간\n(단위:분)', '④임무별 비행시간', '', '', '', '', '비행목적\n(훈련내용)', '⑥교육생', '', '', '⑦지도조종자', '', ''],
                    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
                    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
                    ['', '', '이륙시점\n아워미터', '착륙시점\n아워미터', '아워미터\n기간', '기장', '훈련', '', '교관', '소계', '', '성명', '', '서명', '성명', '자격번호', '서명'],
                    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
                    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']            
                ];
                this.spreadData.push(data);
            }            
        },
        async saveLogBook() {
            let jsonData = [];
            for(let i=0; i<this.machineList.length; i++) {                
                var machineType = this.logList.filter(item =>  {
                    const result = item.droneType === this.machineList[i];                    
                    return result;
                });                
                var sheet = document.getElementById('spreadsheet').jexcel[i];                
                let k=0;
                for(let j=10; j<machineType[0].data.length*2+10; j+=2) {
                    var record = {                        
                        droneCategory: '',
                        droneType: '',
                        droneNo: '',
                        droneWeight: 0,
                        droneMtow: 0,
                        droneOrganization: '',
                        droneOrder: '',
                        flightDate: null,
                        airField: '',
                        startTime: null,
                        endTime: null,
                        soloTime: 0,
                        trainingTime: 0,
                        trainerTime: 0,
                        flightPurpose: '',
                        studentName: '',                
                        instructorName: '',
                        instructorNo: '',                        
                        userId: '',
                        droneId: '',                        
                    };
                    record.droneCategory = sheet.getValueFromCoords(3, 1);                    
                    record.droneType = sheet.getValueFromCoords(9, 1);
                    record.droneNo = sheet.getValueFromCoords(14, 1);
                    record.droneWeight = sheet.getValueFromCoords(3, 2);
                    record.droneMtow = sheet.getValueFromCoords(9, 2);
                    record.droneOrganization = sheet.getValueFromCoords(14, 2);
                    record.droneOrder = machineType[0].droneOrder;
                    if (k<machineType[0].data.length) {

                        var logDateStr = sheet.getValueFromCoords(0, j);
                        var logDate = new Date(`20${logDateStr.replace(/\./g, '-')}`);
                        var logYear = logDate.getFullYear();
                        var logMonth = (logDate.getMonth() + 1).toString().padStart(2,'0');
                        var logDay = logDate.getDate().toString().padStart(2,'0');

                        const startTime = sheet.getValueFromCoords(2, j);
                        const [startHoursString, startMinutesString] = startTime.split(':');
                        const startHours = parseInt(startHoursString, 10);
                        const startMinutes = parseInt(startMinutesString, 10);

                        const startObject = new Date(logYear, logMonth-1, logDay, startHours, startMinutes);

                        const endTime = sheet.getValueFromCoords(3, j);
                        const [endHoursString, endinutesString] = endTime.split(':');
                        const endHours = parseInt(endHoursString, 10);
                        const endMinutes = parseInt(endinutesString, 10);

                        const endObject = new Date(logYear, logMonth-1, logDay, endHours, endMinutes);
                        
                        record.flightDate = logDate;
                        record.airField = sheet.getValueFromCoords(1, j);
                        record.startTime = startObject;
                        record.endTime = endObject;
                        record.soloTime = Number(sheet.getValueFromCoords(5, j));
                        record.trainingTime = Number(sheet.getValueFromCoords(6, j));
                        record.trainerTime = Number(sheet.getValueFromCoords(8, j));
                        record.flightPurpose = sheet.getValueFromCoords(10, j);
                        record.studentName = sheet.getValueFromCoords(11, j);
                        record.instructorName = sheet.getValueFromCoords(14, j);
                        record.instructorNo = sheet.getValueFromCoords(15, j);
                        record.userId = machineType[0].data[k].controlUserId;
                        record.droneId = machineType[0].data[k].deviceId;                        
                        k++;                        
                    }

                    jsonData.push(record);
                }
            }

            const config = {
                method: 'post',                
                url: '/logrecord',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: jsonData
            }

            try {
                await axios(config)
                    .then((res) => {                        
                        const flightDate = res.data[0].flightDate.split('T')[0];
                        const flightIndex = res.data[0].index;                    

                        this.$swal
                            .fire({title: '발급 완료', text: flightDate + '의 ' + flightIndex + '번 증명서가 발급 되었습니다.', icon: 'success'})
                            .then(() => {
                                this.$router.replace({name: 'logbooklist'})
                            })

                        });

            } catch(error) {
                console.error('Error logrecord post', error);
                this.$swal.fire({title: '발급 실패', text: '', icon: 'error'})
            }
            
        },
        setData() {

            for(let i=0; i<this.machineList.length; i++) {
                
                let machineType = this.logBookList.filter(item =>  {
                    const result = item.droneType === this.machineList[i];                    
                    return result;
                });                

                //기체별 시간순 정렬
                machineType[0].data.sort((a,b) => {
                    const timeA = new Date(a.startTime).getTime();
                    const timeB = new Date(b.startTime).getTime();
                    return timeA - timeB;
                })
                
                var sheet = document.getElementById('spreadsheet').jexcel[i];
                sheet.setStyle({ A1:'text-align: right; font-size: 20px' });      
                sheet.insertRow(machineType[0].data.length*2, this.DATA_START_ROW);

                sheet.setValueFromCoords(3, 1, machineType[0].droneCategory);
                sheet.setValueFromCoords(9, 1, machineType[0].droneType);
                sheet.setValueFromCoords(14, 1, machineType[0].droneNo);
                sheet.setValueFromCoords(3, 2, machineType[0].droneWeight + ' kg');
                sheet.setValueFromCoords(9, 2, machineType[0].droneMtow + ' kg');
                sheet.setValueFromCoords(14, 2, machineType[0].droneOrganization);

                let k=0;
                for(let j=10; j<machineType[0].data.length*2+10; j+=2) {
                    sheet.setMerge('A' + (j+1).toString(), 1, 2);
                    sheet.setMerge('B' + (j+1).toString(), 1, 2);
                    sheet.setMerge('F' + (j+1).toString(), 1, 2);
                    sheet.setMerge('G' + (j+1).toString(), 2, 2);
                    sheet.setMerge('I' + (j+1).toString(), 1, 2);
                    sheet.setMerge('J' + (j+1).toString(), 1, 2);
                    sheet.setMerge('K' + (j+1).toString(), 1, 2);
                    sheet.setMerge('L' + (j+1).toString(), 2, 2);
                    sheet.setMerge('N' + (j+1).toString(), 1, 2);
                    sheet.setMerge('O' + (j+1).toString(), 1, 2);
                    sheet.setMerge('P' + (j+1).toString(), 1, 2);
                    sheet.setMerge('Q' + (j+1).toString(), 1, 2);

                    if (k<machineType[0].data.length) {
                        let startDate = new Date(machineType[0].data[k].startTime);                        
                        let startHour = startDate.getHours().toString().padStart(2,'0');                        
                        let startMinute = startDate.getMinutes().toString().padStart(2,'0');                        

                        let formattedStartTime = `${startHour}:${startMinute}`;

                        let endDate = new Date(machineType[0].data[k].endTime);
                        let endHour = endDate.getHours().toString().padStart(2,'0');
                        let endMinute = endDate.getMinutes().toString().padStart(2,'0');

                        let formattedEndTime = `${endHour}:${endMinute}`;
                        let flightMinute = (parseInt(endHour)-parseInt(startHour))*60 + (parseInt(endMinute)-parseInt(startMinute))

                        sheet.setValueFromCoords(0, j, this.formatDate(startDate));
                        sheet.setValueFromCoords(1, j, machineType[0].data[k].airField);
                        sheet.setValueFromCoords(2, j, formattedStartTime);
                        sheet.setValueFromCoords(3, j, formattedEndTime);
                        sheet.setValueFromCoords(4, j, flightMinute);

                        sheet.setValueFromCoords(5, j, machineType[0].data[k].soloTime);
                        sheet.setValueFromCoords(6, j, machineType[0].data[k].trainingTime);
                        sheet.setValueFromCoords(8, j, machineType[0].data[k].trainerTime);
                        

                        let colJ = sheet.getCellFromCoords(8,j);
                        colJ.type = "numeric";
                        sheet.setValueFromCoords(9, j, '=ROUND(SUM(F'+(j+1)+':I'+(j+1)+'),1)');

                        sheet.setValueFromCoords(10, j, machineType[0].data[k].flightPurpose);
                        sheet.setValueFromCoords(11, j, machineType[0].data[k].studentName);
                        sheet.setValueFromCoords(14, j, machineType[0].data[k].instructorName);
                        sheet.setValueFromCoords(15, j, machineType[0].data[k].instructorNo);

                        k++;
                    }
                    this.closeInstructorModal();
                }                
            }            
            let selected = document.getElementById('spreadsheet').children[0].querySelector('.selected').getAttribute('data-spreadsheet');

            if(document.getElementById('spreadsheet').children[0].children[selected]) {
                document.getElementById('spreadsheet').children[0].children[selected].classList.remove("selected");
                document.getElementById('spreadsheet').children[1].children[selected].style.display = "none";
                document.getElementById('spreadsheet').children[0].children[0].classList.add("selected");
                document.getElementById('spreadsheet').children[1].children[0].style.display = "block";
            }           
        },
        setMerge() {
            for(let i=0; i<this.machineList.length; i++) {

                let machineType = this.logList.filter(item =>  {
                    const result = item.droneType === this.machineList[i];                    
                    return result;
                });

                //기체별 시간순 정렬
                machineType[0].data.sort((a,b) => {
                    const dateA = new Date(a.startDate).getTime();
                    const dateB = new Date(b.startDate).getTime();
                    return dateA - dateB;
                })
                
                var sheet = document.getElementById('spreadsheet').jexcel[i];
                sheet.setStyle({ A1:'text-align: right; font-size: 20px' });            
                sheet.insertRow(machineType[0].data.length*2, this.DATA_START_ROW);

                sheet.setValueFromCoords(3, 1, machineType[0].droneCategory);
                sheet.setReadOnly([3,1], true);
                sheet.setStyle({ D2:'color:rgba(0,0,0,1);' });
                sheet.setValueFromCoords(9, 1, machineType[0].droneType);
                sheet.setReadOnly([9,1], true);
                sheet.setStyle({ J2:'color:rgba(0,0,0,1);' });
                sheet.setValueFromCoords(14, 1, machineType[0].droneNo);
                sheet.setReadOnly([14,1], true);
                sheet.setValueFromCoords(3, 2, machineType[0].droneWeight);
                sheet.setReadOnly([3,2], true);
                sheet.setValueFromCoords(9, 2, machineType[0].droneMtow);
                sheet.setReadOnly([9,2], true);
                sheet.setValueFromCoords(14, 2, machineType[0].droneOrganization);
                sheet.setReadOnly([14,2], true);

                let k=0;
                for(let j=10; j<machineType[0].data.length*2+10; j+=2) {
                    sheet.setMerge('A' + (j+1).toString(), 1, 2);
                    sheet.setMerge('B' + (j+1).toString(), 1, 2);
                    sheet.setMerge('F' + (j+1).toString(), 1, 2);
                    sheet.setMerge('G' + (j+1).toString(), 2, 2);
                    sheet.setMerge('I' + (j+1).toString(), 1, 2);
                    sheet.setMerge('J' + (j+1).toString(), 1, 2);
                    sheet.setMerge('K' + (j+1).toString(), 1, 2);
                    sheet.setMerge('L' + (j+1).toString(), 2, 2);
                    sheet.setMerge('N' + (j+1).toString(), 1, 2);
                    sheet.setMerge('O' + (j+1).toString(), 1, 2);
                    sheet.setMerge('P' + (j+1).toString(), 1, 2);
                    sheet.setMerge('Q' + (j+1).toString(), 1, 2);

                    if (k<machineType[0].data.length) {
                        let startDate = new Date(machineType[0].data[k].startDate);
                        
                        let startHour = startDate.getHours().toString().padStart(2,'0');
                        
                        let startMinute = startDate.getMinutes().toString().padStart(2,'0');
                        

                        let formattedStartTime = `${startHour}:${startMinute}`;

                        let endDate = new Date(machineType[0].data[k].endDate);
                        
                        let endHour = endDate.getHours().toString().padStart(2,'0');
                        let endMinute = endDate.getMinutes().toString().padStart(2,'0');

                        let formattedEndTime = `${endHour}:${endMinute}`;
                        let flightMinute = (parseInt(endHour)-parseInt(startHour))*60 + (parseInt(endMinute)-parseInt(startMinute))

                        sheet.setValueFromCoords(0, j, this.formatDate(startDate));
                        sheet.setReadOnly([0, j], true);
                        sheet.setValueFromCoords(2, j, formattedStartTime);
                        sheet.setReadOnly([2, j], true);
                        sheet.setValueFromCoords(3, j, formattedEndTime);
                        sheet.setReadOnly([3, j], true);
                        sheet.setValueFromCoords(4, j, flightMinute);
                        sheet.setReadOnly([4, j], true);
                        
                        if (machineType[0].data[k].controlUserType.toLowerCase() === 'solo') {
                            sheet.setValueFromCoords(5, j, Math.floor((flightMinute/60)*10)/10);
                            sheet.setReadOnly([5, j], true);
                        } else if (machineType[0].data[k].controlUserType.toLowerCase() === 'training') {
                            sheet.setValueFromCoords(6, j, Math.floor((flightMinute/60)*10)/10);
                            sheet.setReadOnly([6, j], true);
                        } else if (machineType[0].data[k].controlUserType.toLowerCase() === 'trainer') {
                            sheet.setValueFromCoords(8, j, Math.floor((flightMinute/60)*10)/10);
                            sheet.setReadOnly([8, j], true);
                        }

                        let colJ = sheet.getCellFromCoords(8,j);
                        colJ.type = "numeric";
                        sheet.setValueFromCoords(9, j, '=ROUND(SUM(F'+(j+1)+':I'+(j+1)+'),1)');
                        sheet.setReadOnly([9, j], true);
                        sheet.setValueFromCoords(11, j, machineType[0].data[k].userName);
                        sheet.setReadOnly([11, j], true);


                        sheet.setStyle('B'+(j+1).toString(),'background-color','#fffacd');
                        sheet.setStyle('K'+(j+1).toString(),'background-color','#fffacd');
                        sheet.setStyle('O'+(j+1).toString(),'background-color','#fffacd');
                        sheet.setStyle('P'+(j+1).toString(),'background-color','#fffacd');


                        k++;
                    }
                    this.closeInstructorModal();
                }                
            }

            let selected = document.getElementById('spreadsheet').children[0].querySelector('.selected').getAttribute('data-spreadsheet');

            if(document.getElementById('spreadsheet').children[0].children[selected]) {
                document.getElementById('spreadsheet').children[0].children[selected].classList.remove("selected");
                document.getElementById('spreadsheet').children[1].children[selected].style.display = "none";
                document.getElementById('spreadsheet').children[0].children[0].classList.add("selected");
                document.getElementById('spreadsheet').children[1].children[0].style.display = "block";
            }
        },        
        formatDate(date) {
            const year = date.getFullYear() % 100;
            const month = ('0' + (date.getMonth()+1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);

            return `${year}.${month}.${day}`;
        },    
        async setJSpreadsheet() {            
            for (let i=0; i < this.machineList.length; i++) {
                this.tabs[i] = ({
                    sheetName: this.machineList[i],                
                    data: this.spreadData[i],
                    columns: [                    
                        {type:'text', width:90 },
                        {type:'text', width:100 },
                        {type:'text', width:90 },
                        {type:'text', width:80 },
                        {type:'text', width:80 },
                        {type:'text', width:80 },
                        {type:'text', width:40 },
                        {type:'text', width:40 },
                        {type:'text', width:80 },
                        {type:'text', width:80 },
                        {type:'text', width:200 },
                        {type:'text', width:30 },
                        {type:'text', width:50 },
                        {type:'text', width:80 },
                        {type:'text', width:80 },
                        {type:'text', width:100 },
                        {type:'text', width:80 }                    
                    ],             
                    mergeCells: {
                        A1: [11,1],
                        M1: [5,1],
                        A2: [2,2],
                        D2: [4,1],
                        H2: [2,1],
                        J2: [3,1],
                        M2: [2,1],
                        O2: [3,1],
                        D3: [4,1],
                        H3: [2,1],
                        J3: [3,1],
                        M3: [2,1],
                        O3: [2,1],
                        A5: [1,6],
                        B5: [1,6],
                        C5: [1,3],
                        D5: [1,3],
                        E5: [1,3],
                        F5: [5,3],
                        K5: [1,6],                    
                        L5: [3,3],
                        O5: [3,3],
                        C8: [1,3],
                        D8: [1,3],
                        E8: [1,3],
                        F8: [1,3],
                        G8: [2,3],
                        I8: [1,3],
                        J8: [1,3],
                        L8: [2,3],
                        N8: [1,3],
                        O8: [1,3],
                        P8: [1,3],
                        Q8: [1,3]                    
                    },              
                    minDimensions: [10,5],  
                    wordWrap: true,
                    onselection: this.selectionActive,
                    contextMenu: false
                });                
            }
            
            jspreadsheet.tabs(document.getElementById('spreadsheet'), this.tabs);

        },
        selectionActive(instance, x1, y1, x2, y2) {
            if(!instance) {
                return;
            }
            
            if (this.source === "issue") {

                this.selectedRow = y1;
                this.selectedCol = x1;

                const element = document.getElementById('spreadsheet').children[0].querySelector('.selected');

                if(element) {
                    this.selectedTab = document.getElementById('spreadsheet').children[0].querySelector('.selected').getAttribute('data-spreadsheet');
                    
                    if(!this.isModal) {
                        if (x1 === x2 && y1 === y2-1) {

                            let sheet = document.getElementById('spreadsheet').jexcel[this.selectedTab];
                            const mergeInfo = sheet.isRowMerged(y1);


                            if(!mergeInfo) {
                                return;
                            }                            
                            const mergedCells = mergeInfo.toString().split(',');
                            const isTwelveMergedCells = mergedCells.length === 12;
                            if (!isTwelveMergedCells) {
                                return;
                            }

                            //비행장소 선택팝업
                            if (x1 === 1 && y1 >= 10) {
                                this.airFieldModal = true;
                                this.isModal = true;
                            }                        

                            //비행목적 선택팝업
                            if (x1 === 10 && y1 >= 10) {
                                //this.logPurposeModal = true;
                                this.purposeModal = true;
                                this.isModal = true;
                            }

                            //지도조종자 선택팝업
                            if (x1 >= 14 && x1 <=15 && y1 >= 10) {
                                this.instructorModal = true;
                                this.isModal = true;
                            }
                            
                        }
                    }
                }
            }
                
            //}
        },
        selectAllCheckboxes() {
            const newValue = this.checkSelectAll;
            this.checkboxes.forEach((checkbox) => {
                checkbox.value = newValue;
            });
        },
        moveBack() {
            if (this.source == "issue") {
                this.$router.replace({name: 'logbookissue'})
            } else if (this.source ==="reissue") {
                this.$router.replace({name: 'logbooklist'})
            }
        },
        async download(workbook, fileName){
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = fileName + ".xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
        },
        makeExcel() {
            this.sheets = [];
            this.workbook = new exceljs.Workbook();
            for(var i=0; i<this.machineList.length; i++) {
                var sheetName = this.machineList[i];
                const sheet = this.workbook.addWorksheet(sheetName, {
                        pageSetup: {
                        paperSize: 9,
                        orientation: 'landscape',
                        fitToPage: true,
                        fitToWidth: 1,
                        fitToHeight: 0,
                    },
                    views: [{}]
                });

                let machineType = this.logBookList.filter(item =>  {
                    const result = item.droneType === this.machineList[i];                    
                    return result;
                });

                const pages = Math.ceil(machineType[0].data.length/this.PAGE_PER_DATA);
                
                this.sheets.push(sheet);

                var length = this.PAGE_PER_DATA*2;
                const lastPage = Math.ceil(machineType[0].data.length/this.PAGE_PER_DATA);
                const lastLength = machineType[0].data.length%this.PAGE_PER_DATA;

                for(var j=0; j<pages; j++) {
                    this.setSheetData_header(i,j);
                    this.setMergeCell_header(i,j);
                    this.setDrawBorder_header(i,j);
                    this.setSheetSize_header(i,j);
                    this.setMergeCell(i,j);
                    if (lastLength !== 0 && j === lastPage-1) {
                        length = lastLength*2;
                    }
                    this.setSheetData(i,j,length);
                    this.setDrawBorder(i,j);
                    this.setSheetSize(i,j);
                    this.setSheetData_footer(i,j);
                    this.sheets[i].mergeCells("A"+(43+j*44).toString()+" : "+"Q"+(44+j*44).toString());
                    this.setSheetSize_footer(i,j);

                    this.sheets[i].getRow((j+1)*44).addPageBreak();                    
                }
            }

            var issueDate = new Date(`20${this.logBookInfo.flightDate.replace(/\./g, '-')}`);
            var formattedDate = `${issueDate.getFullYear()}${this.padZero(issueDate.getMonth() + 1)}${this.padZero(issueDate.getDate())}`;
            var docNum = this.logBookInfo.index;
            var fileName = formattedDate + '_' + docNum + '_기체비행기록부';
            this.download(this.workbook, fileName);
        },
        setSheetData_header(index, page) {
            var MalgunGothic = "Malgun Gothic";
            var Center = "center";
            var Left = "left";
            var Right = "right";

            this.setCell_header(index, "A"+(1+page*44).toString(), "기 체 비 행 기 록 부(Flight Logbook)", MalgunGothic, 22, Right, true);
            this.setCell(index, "M"+(1+page*44).toString(), "*날씨:         *일출:         *일몰:", MalgunGothic, 14, Left);
            this.setCell(index, "A"+(2+page*44).toString(), "기체(機體) 정보", MalgunGothic, 12, Center);

            this.setCell(index, "C"+(2+page*44).toString(), "종     류", MalgunGothic, 12, Center, true);
            this.setCell(index, "H"+(2+page*44).toString(), "형           식", MalgunGothic, 12, Center, true);
            this.setCell(index, "M"+(2+page*44).toString(), "신고번호", MalgunGothic, 12, Center, true);
            this.setCell(index, "C"+(3+page*44).toString(), "자체중량", MalgunGothic, 12, Center, true);
            this.setCell(index, "H"+(3+page*44).toString(), "최대이륙중량", MalgunGothic, 12, Center, true);
            
            this.setCell(index, "M"+(3+page*44).toString(), "인정기관", MalgunGothic, 12, Center, true);
            this.setCell(index, "Q"+(3+page*44).toString(), "(서명)", MalgunGothic, 12, Center, true);
            
            this.setCell(index, "A"+(5+page*44).toString(), "①연월일", MalgunGothic, 12);
            this.setCell(index, "B"+(5+page*44).toString(), "②비행장소", MalgunGothic, 12);
            this.setCell(index, "C"+(5+page*44).toString(), "이륙 시각", MalgunGothic, 12);
            this.setCell(index, "C"+(8+page*44).toString(), "이륙시점 아워미터", MalgunGothic, 12);
            this.setCell(index, "D"+(5+page*44).toString(), "착륙 시각", MalgunGothic, 12);
            this.setCell(index, "D"+(8+page*44).toString(), "착륙시점 아워미터", MalgunGothic, 12);
            this.setCell(index, "E"+(5+page*44).toString(), "비행시간\n(단위:분)", MalgunGothic, 12);
            this.setCell(index, "E"+(8+page*44).toString(), "아워미터 기간", MalgunGothic, 12);
            this.setCell(index, "F"+(5+page*44).toString(), "④임무별 비행시간", MalgunGothic, 12);
            this.setCell(index, "F"+(8+page*44).toString(), "기장", MalgunGothic, 12);
            this.setCell(index, "G"+(8+page*44).toString(), "훈련", MalgunGothic, 12);
            this.setCell(index, "I"+(8+page*44).toString(), "교관", MalgunGothic, 12);
            this.setCell(index, "J"+(8+page*44).toString(), "소계", MalgunGothic, 12);
            this.setCell(index, "K"+(5+page*44).toString(), "비행목적 \n(훈련내용)", MalgunGothic, 12);
            this.setCell(index, "L"+(5+page*44).toString(), "⑥ 교육생", MalgunGothic, 12);
            this.setCell(index, "L"+(8+page*44).toString(), "성명", MalgunGothic, 12);
            this.setCell(index, "N"+(8+page*44).toString(), "서명", MalgunGothic, 12);
            this.setCell(index, "O"+(5+page*44).toString(), "⑦지도조종자", MalgunGothic, 12);
            this.setCell(index, "O"+(8+page*44).toString(), "성명", MalgunGothic, 12);
            this.setCell(index, "P"+(8+page*44).toString(), "자격번호", MalgunGothic, 12);
            this.setCell(index, "Q"+(8+page*44).toString(), "서명", MalgunGothic, 12);            
            
        },
        setCell_header(
            index,          
            cellName,
            msg,
            fontName,
            fontSize,
            wAliment = "center",
            bBold = false,
            ) {                
            this.sheets[index].getCell(cellName).value = msg;
            this.sheets[index].getCell(cellName).alignment = {
                vertical: "middle",
                horizontal: wAliment,
                wrapText: true,
            };
            this.sheets[index].getCell(cellName).font = {
                name: fontName,
                size: fontSize,
                bold: bBold,
                underline: "double",
            };
        },
        setCell(
            index,
            cellName,
            msg,
            fontName,
            fontSize,
            wAliment = "center",
            bBold = false,
            bUnderline = false
            ) {
            this.sheets[index].getCell(cellName).value = msg;
            this.sheets[index].getCell(cellName).alignment = {
                vertical: "middle",
                horizontal: wAliment,
                wrapText: true,
            };
            this.sheets[index].getCell(cellName).font = {
                name: fontName,
                size: fontSize,
                bold: bBold,
                underline: bUnderline ? true : false,
            };
        },
        setMergeCell_header(index, page){
            this.sheets[index].mergeCells("A"+(1+page*44).toString()+" : "+"K"+(1+page*44).toString());
            this.sheets[index].mergeCells("M"+(1+page*44).toString()+" : "+"Q"+(1+page*44).toString());
            this.sheets[index].mergeCells("A"+(2+page*44).toString()+" : "+"B"+(3+page*44).toString());

            this.sheets[index].mergeCells("D"+(2+page*44).toString()+" : "+"G"+(2+page*44).toString());
            
            this.sheets[index].mergeCells("D"+(3+page*44).toString()+" : "+"G"+(3+page*44).toString());
            
            this.sheets[index].mergeCells("H"+(2+page*44).toString()+" : "+"I"+(2+page*44).toString());
            this.sheets[index].mergeCells("J"+(2+page*44).toString()+" : "+"L"+(2+page*44).toString());
            this.sheets[index].mergeCells("H"+(3+page*44).toString()+" : "+"I"+(3+page*44).toString());
            this.sheets[index].mergeCells("J"+(3+page*44).toString()+" : "+"L"+(3+page*44).toString());

            this.sheets[index].mergeCells("M"+(2+page*44).toString()+" : "+"N"+(2+page*44).toString());
            this.sheets[index].mergeCells("O"+(2+page*44).toString()+" : "+"Q"+(2+page*44).toString());
            this.sheets[index].mergeCells("M"+(3+page*44).toString()+" : "+"N"+(3+page*44).toString());
            this.sheets[index].mergeCells("O"+(3+page*44).toString()+" : "+"P"+(3+page*44).toString());

            this.sheets[index].mergeCells("A"+(4+page*44).toString()+" : "+"Q"+(4+page*44).toString());

            this.sheets[index].mergeCells("A"+(5+page*44).toString()+" : "+"A"+(10+page*44).toString());
            this.sheets[index].mergeCells("B"+(5+page*44).toString()+" : "+"B"+(10+page*44).toString());
            this.sheets[index].mergeCells("C"+(5+page*44).toString()+" : "+"C"+(7+page*44).toString());
            this.sheets[index].mergeCells("C"+(8+page*44).toString()+" : "+"C"+(10+page*44).toString());
            this.sheets[index].mergeCells("D"+(5+page*44).toString()+" : "+"D"+(7+page*44).toString());
            this.sheets[index].mergeCells("D"+(8+page*44).toString()+" : "+"D"+(10+page*44).toString());
            this.sheets[index].mergeCells("E"+(5+page*44).toString()+" : "+"E"+(7+page*44).toString());
            this.sheets[index].mergeCells("E"+(8+page*44).toString()+" : "+"E"+(10+page*44).toString());
            this.sheets[index].mergeCells("F"+(5+page*44).toString()+" : "+"J"+(7+page*44).toString());
            this.sheets[index].mergeCells("F"+(8+page*44).toString()+" : "+"F"+(10+page*44).toString());
            this.sheets[index].mergeCells("G"+(8+page*44).toString()+" : "+"H"+(10+page*44).toString());
            this.sheets[index].mergeCells("I"+(8+page*44).toString()+" : "+"I"+(10+page*44).toString());
            this.sheets[index].mergeCells("J"+(8+page*44).toString()+" : "+"J"+(10+page*44).toString());
            this.sheets[index].mergeCells("K"+(5+page*44).toString()+" : "+"K"+(10+page*44).toString());
            this.sheets[index].mergeCells("L"+(5+page*44).toString()+" : "+"N"+(7+page*44).toString());
            this.sheets[index].mergeCells("L"+(8+page*44).toString()+" : "+"M"+(10+page*44).toString());
            this.sheets[index].mergeCells("N"+(8+page*44).toString()+" : "+"N"+(10+page*44).toString());
            this.sheets[index].mergeCells("O"+(5+page*44).toString()+" : "+"Q"+(7+page*44).toString());
            this.sheets[index].mergeCells("O"+(8+page*44).toString()+" : "+"O"+(10+page*44).toString());
            this.sheets[index].mergeCells("P"+(8+page*44).toString()+" : "+"P"+(10+page*44).toString());
            this.sheets[index].mergeCells("Q"+(8+page*44).toString()+" : "+"Q"+(10+page*44).toString());
        },
        setSheetSize_header(index, page) {      
            this.sheets[index].getColumn(1).width = 10.63 + 0.63;
            this.sheets[index].getColumn(2).width = 11.31 + 0.63;
            this.sheets[index].getColumn(3).width = 10.13 + 0.63;
            this.sheets[index].getColumn(4).width = 9.38 + 0.63;
            this.sheets[index].getColumn(5).width = 8.38 + 0.63;
            this.sheets[index].getColumn(6).width = 8.63 + 0.63;
            this.sheets[index].getColumn(7).width = 4.25 + 0.63;
            this.sheets[index].getColumn(8).width = 4.25 + 0.63;
            this.sheets[index].getColumn(9).width = 9 + 0.63;
            this.sheets[index].getColumn(10).width = 9 + 0.63;
            this.sheets[index].getColumn(11).width = 33.13 + 0.63;
            this.sheets[index].getColumn(12).width = 2.5 + 0.63;
            this.sheets[index].getColumn(13).width = 4.63 + 0.63;
            this.sheets[index].getColumn(14).width = 8.38 + 0.63;
            this.sheets[index].getColumn(15).width = 8.38 + 0.63;
            this.sheets[index].getColumn(16).width = 11.13 + 0.63;
            this.sheets[index].getColumn(17).width = 9 + 0.63;

            this.sheets[index].getRow(1+page*44).height = 32.25;
            this.sheets[index].getRow(2+page*44).height = 15;
            this.sheets[index].getRow(3+page*44).height = 15;
            this.sheets[index].getRow(4+page*44).height = 15;
            this.sheets[index].getRow(5+page*44).height = 15;
            this.sheets[index].getRow(6+page*44).height = 15;
            this.sheets[index].getRow(7+page*44).height = 15;
            this.sheets[index].getRow(8+page*44).height = 15;
            this.sheets[index].getRow(9+page*44).height = 15;
            this.sheets[index].getRow(10+page*44).height = 15;
        },
        setDrawBorder_header(index, page) {
            this.drawBorderLine(index, "A"+(2+page*44).toString(), this.constants.LEFT_LINE, true);
            //drawBorderLine("A2", ALL_LINE);  
            this.drawBorderLine(index, "C"+(2+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(3+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "D"+(2+page*44).toString(), this.constants.ALL_LINE);        
            this.drawBorderLine(index, "D"+(3+page*44).toString(), this.constants.ALL_LINE);            

            this.drawBorderLine(index, "H"+(2+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "H"+(3+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "J"+(2+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(3+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "M"+(2+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "M"+(3+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "O"+(2+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(3+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(3+page*44).toString(), this.constants.ALL_LINE);


            this.drawBorderLine(index, "A"+(4+page*44).toString(), this.constants.TOP_LINE, true);
            this.drawBorderLine(index, "A"+(1+page*44).toString(), this.constants.BOTTOM_LINE, true);
            this.drawBorderLine(index, "L"+(1+page*44).toString(), this.constants.BOTTOM_LINE, true);
            this.drawBorderLine(index, "M"+(1+page*44).toString(), this.constants.BOTTOM_LINE, true);
            this.drawBorderLine(index, "R"+(2+page*44).toString(), this.constants.LEFT_LINE, true);
            this.drawBorderLine(index, "R"+(3+page*44).toString(), this.constants.LEFT_LINE, true);


            this.drawBorderLine(index, "A"+(5+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(5+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(5+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(8+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(5+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(8+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(5+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(8+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(5+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(8+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(8+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(8+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(8+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(5+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(5+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(8+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(8+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(5+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(8+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(8+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(8+page*44).toString(), this.constants.ALL_LINE);
        },
        drawBorderLine(index, targetCell, flag, value = false) {
            this.sheets[index].getCell(targetCell).border = this.getBorderStyle(flag,value);
        },
        getBorderStyle(flag, value) {
            const myBorder = {
                top: { style: "" },
                left: { style: "" },
                right: { style: "" },
                bottom: { style: "" },
            };

            if(value == true)
            {
                if (Number(flag & this.constants.TOP_LINE) == this.constants.TOP_LINE) {
                    myBorder.top.style = "medium";
                }
                if (Number(flag & this.constants.LEFT_LINE) == this.constants.LEFT_LINE) {
                    myBorder.left.style = "medium";
                }
                if (Number(flag & this.constants.RIGHT_LINE) == this.constants.RIGHT_LINE) {
                    myBorder.right.style = "medium";
                }
                if (Number(flag & this.constants.BOTTOM_LINE) == this.constants.BOTTOM_LINE) {
                    myBorder.bottom.style = "medium";
                }
                return myBorder;
            }

            if (Number(flag & this.constants.TOP_LINE) == this.constants.TOP_LINE) {
                myBorder.top.style = "thin";
            }
            if (Number(flag & this.constants.LEFT_LINE) == this.constants.LEFT_LINE) {
                myBorder.left.style = "thin";
            }
            if (Number(flag & this.constants.RIGHT_LINE) == this.constants.RIGHT_LINE) {
                myBorder.right.style = "thin";
            }
            if (Number(flag & this.constants.BOTTOM_LINE) == this.constants.BOTTOM_LINE) {
                myBorder.bottom.style = "thin";
            }
            return myBorder;
        },
        setMergeCell(index, page) {            
            //data
            this.sheets[index].mergeCells("A"+(11+page*44).toString()+" : "+"A"+(12+page*44).toString());
            this.sheets[index].mergeCells("B"+(11+page*44).toString()+" : "+"B"+(12+page*44).toString());
            this.sheets[index].mergeCells("F"+(11+page*44).toString()+" : "+"F"+(12+page*44).toString());
            this.sheets[index].mergeCells("G"+(11+page*44).toString()+" : "+"H"+(12+page*44).toString());
            this.sheets[index].mergeCells("I"+(11+page*44).toString()+" : "+"I"+(12+page*44).toString());
            this.sheets[index].mergeCells("J"+(11+page*44).toString()+" : "+"J"+(12+page*44).toString());
            this.sheets[index].mergeCells("K"+(11+page*44).toString()+" : "+"K"+(12+page*44).toString());
            this.sheets[index].mergeCells("L"+(11+page*44).toString()+" : "+"M"+(12+page*44).toString());
            this.sheets[index].mergeCells("N"+(11+page*44).toString()+" : "+"N"+(12+page*44).toString());
            this.sheets[index].mergeCells("O"+(11+page*44).toString()+" : "+"O"+(12+page*44).toString());
            this.sheets[index].mergeCells("P"+(11+page*44).toString()+" : "+"P"+(12+page*44).toString());
            this.sheets[index].mergeCells("Q"+(11+page*44).toString()+" : "+"Q"+(12+page*44).toString());

            this.sheets[index].mergeCells("A"+(13+page*44).toString()+" : "+"A"+(14+page*44).toString());
            this.sheets[index].mergeCells("B"+(13+page*44).toString()+" : "+"B"+(14+page*44).toString());
            this.sheets[index].mergeCells("F"+(13+page*44).toString()+" : "+"F"+(14+page*44).toString());
            this.sheets[index].mergeCells("G"+(13+page*44).toString()+" : "+"H"+(14+page*44).toString());
            this.sheets[index].mergeCells("I"+(13+page*44).toString()+" : "+"I"+(14+page*44).toString());
            this.sheets[index].mergeCells("J"+(13+page*44).toString()+" : "+"J"+(14+page*44).toString());
            this.sheets[index].mergeCells("K"+(13+page*44).toString()+" : "+"K"+(14+page*44).toString());
            this.sheets[index].mergeCells("L"+(13+page*44).toString()+" : "+"M"+(14+page*44).toString());
            this.sheets[index].mergeCells("N"+(13+page*44).toString()+" : "+"N"+(14+page*44).toString());
            this.sheets[index].mergeCells("O"+(13+page*44).toString()+" : "+"O"+(14+page*44).toString());
            this.sheets[index].mergeCells("P"+(13+page*44).toString()+" : "+"P"+(14+page*44).toString());
            this.sheets[index].mergeCells("Q"+(13+page*44).toString()+" : "+"Q"+(14+page*44).toString());

            this.sheets[index].mergeCells("A"+(15+page*44).toString()+" : "+"A"+(16+page*44).toString());
            this.sheets[index].mergeCells("B"+(15+page*44).toString()+" : "+"B"+(16+page*44).toString());
            this.sheets[index].mergeCells("F"+(15+page*44).toString()+" : "+"F"+(16+page*44).toString());
            this.sheets[index].mergeCells("G"+(15+page*44).toString()+" : "+"H"+(16+page*44).toString());
            this.sheets[index].mergeCells("I"+(15+page*44).toString()+" : "+"I"+(16+page*44).toString());
            this.sheets[index].mergeCells("J"+(15+page*44).toString()+" : "+"J"+(16+page*44).toString());
            this.sheets[index].mergeCells("K"+(15+page*44).toString()+" : "+"K"+(16+page*44).toString());
            this.sheets[index].mergeCells("L"+(15+page*44).toString()+" : "+"M"+(16+page*44).toString());
            this.sheets[index].mergeCells("N"+(15+page*44).toString()+" : "+"N"+(16+page*44).toString());
            this.sheets[index].mergeCells("O"+(15+page*44).toString()+" : "+"O"+(16+page*44).toString());
            this.sheets[index].mergeCells("P"+(15+page*44).toString()+" : "+"P"+(16+page*44).toString());
            this.sheets[index].mergeCells("Q"+(15+page*44).toString()+" : "+"Q"+(16+page*44).toString());

            this.sheets[index].mergeCells("A"+(17+page*44).toString()+" : "+"A"+(18+page*44).toString());
            this.sheets[index].mergeCells("B"+(17+page*44).toString()+" : "+"B"+(18+page*44).toString());
            this.sheets[index].mergeCells("F"+(17+page*44).toString()+" : "+"F"+(18+page*44).toString());
            this.sheets[index].mergeCells("G"+(17+page*44).toString()+" : "+"H"+(18+page*44).toString());
            this.sheets[index].mergeCells("I"+(17+page*44).toString()+" : "+"I"+(18+page*44).toString());
            this.sheets[index].mergeCells("J"+(17+page*44).toString()+" : "+"J"+(18+page*44).toString());
            this.sheets[index].mergeCells("K"+(17+page*44).toString()+" : "+"K"+(18+page*44).toString());
            this.sheets[index].mergeCells("L"+(17+page*44).toString()+" : "+"M"+(18+page*44).toString());
            this.sheets[index].mergeCells("N"+(17+page*44).toString()+" : "+"N"+(18+page*44).toString());
            this.sheets[index].mergeCells("O"+(17+page*44).toString()+" : "+"O"+(18+page*44).toString());
            this.sheets[index].mergeCells("P"+(17+page*44).toString()+" : "+"P"+(18+page*44).toString());
            this.sheets[index].mergeCells("Q"+(17+page*44).toString()+" : "+"Q"+(18+page*44).toString());

            this.sheets[index].mergeCells("A"+(19+page*44).toString()+" : "+"A"+(20+page*44).toString());
            this.sheets[index].mergeCells("B"+(19+page*44).toString()+" : "+"B"+(20+page*44).toString());
            this.sheets[index].mergeCells("F"+(19+page*44).toString()+" : "+"F"+(20+page*44).toString());
            this.sheets[index].mergeCells("G"+(19+page*44).toString()+" : "+"H"+(20+page*44).toString());
            this.sheets[index].mergeCells("I"+(19+page*44).toString()+" : "+"I"+(20+page*44).toString());
            this.sheets[index].mergeCells("J"+(19+page*44).toString()+" : "+"J"+(20+page*44).toString());
            this.sheets[index].mergeCells("K"+(19+page*44).toString()+" : "+"K"+(20+page*44).toString());
            this.sheets[index].mergeCells("L"+(19+page*44).toString()+" : "+"M"+(20+page*44).toString());
            this.sheets[index].mergeCells("N"+(19+page*44).toString()+" : "+"N"+(20+page*44).toString());
            this.sheets[index].mergeCells("O"+(19+page*44).toString()+" : "+"O"+(20+page*44).toString());
            this.sheets[index].mergeCells("P"+(19+page*44).toString()+" : "+"P"+(20+page*44).toString());
            this.sheets[index].mergeCells("Q"+(19+page*44).toString()+" : "+"Q"+(20+page*44).toString());

            this.sheets[index].mergeCells("A"+(21+page*44).toString()+" : "+"A"+(22+page*44).toString());
            this.sheets[index].mergeCells("B"+(21+page*44).toString()+" : "+"B"+(22+page*44).toString());
            this.sheets[index].mergeCells("F"+(21+page*44).toString()+" : "+"F"+(22+page*44).toString());
            this.sheets[index].mergeCells("G"+(21+page*44).toString()+" : "+"H"+(22+page*44).toString());
            this.sheets[index].mergeCells("I"+(21+page*44).toString()+" : "+"I"+(22+page*44).toString());
            this.sheets[index].mergeCells("J"+(21+page*44).toString()+" : "+"J"+(22+page*44).toString());
            this.sheets[index].mergeCells("K"+(21+page*44).toString()+" : "+"K"+(22+page*44).toString());
            this.sheets[index].mergeCells("L"+(21+page*44).toString()+" : "+"M"+(22+page*44).toString());
            this.sheets[index].mergeCells("N"+(21+page*44).toString()+" : "+"N"+(22+page*44).toString());
            this.sheets[index].mergeCells("O"+(21+page*44).toString()+" : "+"O"+(22+page*44).toString());
            this.sheets[index].mergeCells("P"+(21+page*44).toString()+" : "+"P"+(22+page*44).toString());
            this.sheets[index].mergeCells("Q"+(21+page*44).toString()+" : "+"Q"+(22+page*44).toString());

            this.sheets[index].mergeCells("A"+(23+page*44).toString()+" : "+"A"+(24+page*44).toString());
            this.sheets[index].mergeCells("B"+(23+page*44).toString()+" : "+"B"+(24+page*44).toString());
            this.sheets[index].mergeCells("F"+(23+page*44).toString()+" : "+"F"+(24+page*44).toString());
            this.sheets[index].mergeCells("G"+(23+page*44).toString()+" : "+"H"+(24+page*44).toString());
            this.sheets[index].mergeCells("I"+(23+page*44).toString()+" : "+"I"+(24+page*44).toString());
            this.sheets[index].mergeCells("J"+(23+page*44).toString()+" : "+"J"+(24+page*44).toString());
            this.sheets[index].mergeCells("K"+(23+page*44).toString()+" : "+"K"+(24+page*44).toString());
            this.sheets[index].mergeCells("L"+(23+page*44).toString()+" : "+"M"+(24+page*44).toString());
            this.sheets[index].mergeCells("N"+(23+page*44).toString()+" : "+"N"+(24+page*44).toString());
            this.sheets[index].mergeCells("O"+(23+page*44).toString()+" : "+"O"+(24+page*44).toString());
            this.sheets[index].mergeCells("P"+(23+page*44).toString()+" : "+"P"+(24+page*44).toString());
            this.sheets[index].mergeCells("Q"+(23+page*44).toString()+" : "+"Q"+(24+page*44).toString());

            this.sheets[index].mergeCells("A"+(25+page*44).toString()+" : "+"A"+(26+page*44).toString());
            this.sheets[index].mergeCells("B"+(25+page*44).toString()+" : "+"B"+(26+page*44).toString());
            this.sheets[index].mergeCells("F"+(25+page*44).toString()+" : "+"F"+(26+page*44).toString());
            this.sheets[index].mergeCells("G"+(25+page*44).toString()+" : "+"H"+(26+page*44).toString());
            this.sheets[index].mergeCells("I"+(25+page*44).toString()+" : "+"I"+(26+page*44).toString());
            this.sheets[index].mergeCells("J"+(25+page*44).toString()+" : "+"J"+(26+page*44).toString());
            this.sheets[index].mergeCells("K"+(25+page*44).toString()+" : "+"K"+(26+page*44).toString());
            this.sheets[index].mergeCells("L"+(25+page*44).toString()+" : "+"M"+(26+page*44).toString());
            this.sheets[index].mergeCells("N"+(25+page*44).toString()+" : "+"N"+(26+page*44).toString());
            this.sheets[index].mergeCells("O"+(25+page*44).toString()+" : "+"O"+(26+page*44).toString());
            this.sheets[index].mergeCells("P"+(25+page*44).toString()+" : "+"P"+(26+page*44).toString());
            this.sheets[index].mergeCells("Q"+(25+page*44).toString()+" : "+"Q"+(26+page*44).toString());

            this.sheets[index].mergeCells("A"+(27+page*44).toString()+" : "+"A"+(28+page*44).toString());
            this.sheets[index].mergeCells("B"+(27+page*44).toString()+" : "+"B"+(28+page*44).toString());
            this.sheets[index].mergeCells("F"+(27+page*44).toString()+" : "+"F"+(28+page*44).toString());
            this.sheets[index].mergeCells("G"+(27+page*44).toString()+" : "+"H"+(28+page*44).toString());
            this.sheets[index].mergeCells("I"+(27+page*44).toString()+" : "+"I"+(28+page*44).toString());
            this.sheets[index].mergeCells("J"+(27+page*44).toString()+" : "+"J"+(28+page*44).toString());
            this.sheets[index].mergeCells("K"+(27+page*44).toString()+" : "+"K"+(28+page*44).toString());
            this.sheets[index].mergeCells("L"+(27+page*44).toString()+" : "+"M"+(28+page*44).toString());
            this.sheets[index].mergeCells("N"+(27+page*44).toString()+" : "+"N"+(28+page*44).toString());
            this.sheets[index].mergeCells("O"+(27+page*44).toString()+" : "+"O"+(28+page*44).toString());
            this.sheets[index].mergeCells("P"+(27+page*44).toString()+" : "+"P"+(28+page*44).toString());
            this.sheets[index].mergeCells("Q"+(27+page*44).toString()+" : "+"Q"+(28+page*44).toString());

            this.sheets[index].mergeCells("A"+(29+page*44).toString()+" : "+"A"+(30+page*44).toString());
            this.sheets[index].mergeCells("B"+(29+page*44).toString()+" : "+"B"+(30+page*44).toString());
            this.sheets[index].mergeCells("F"+(29+page*44).toString()+" : "+"F"+(30+page*44).toString());
            this.sheets[index].mergeCells("G"+(29+page*44).toString()+" : "+"H"+(30+page*44).toString());
            this.sheets[index].mergeCells("I"+(29+page*44).toString()+" : "+"I"+(30+page*44).toString());
            this.sheets[index].mergeCells("J"+(29+page*44).toString()+" : "+"J"+(30+page*44).toString());
            this.sheets[index].mergeCells("K"+(29+page*44).toString()+" : "+"K"+(30+page*44).toString());
            this.sheets[index].mergeCells("L"+(29+page*44).toString()+" : "+"M"+(30+page*44).toString());
            this.sheets[index].mergeCells("N"+(29+page*44).toString()+" : "+"N"+(30+page*44).toString());
            this.sheets[index].mergeCells("O"+(29+page*44).toString()+" : "+"O"+(30+page*44).toString());
            this.sheets[index].mergeCells("P"+(29+page*44).toString()+" : "+"P"+(30+page*44).toString());
            this.sheets[index].mergeCells("Q"+(29+page*44).toString()+" : "+"Q"+(30+page*44).toString());

            this.sheets[index].mergeCells("A"+(31+page*44).toString()+" : "+"A"+(32+page*44).toString());
            this.sheets[index].mergeCells("B"+(31+page*44).toString()+" : "+"B"+(32+page*44).toString());
            this.sheets[index].mergeCells("F"+(31+page*44).toString()+" : "+"F"+(32+page*44).toString());
            this.sheets[index].mergeCells("G"+(31+page*44).toString()+" : "+"H"+(32+page*44).toString());
            this.sheets[index].mergeCells("I"+(31+page*44).toString()+" : "+"I"+(32+page*44).toString());
            this.sheets[index].mergeCells("J"+(31+page*44).toString()+" : "+"J"+(32+page*44).toString());
            this.sheets[index].mergeCells("K"+(31+page*44).toString()+" : "+"K"+(32+page*44).toString());
            this.sheets[index].mergeCells("L"+(31+page*44).toString()+" : "+"M"+(32+page*44).toString());
            this.sheets[index].mergeCells("N"+(31+page*44).toString()+" : "+"N"+(32+page*44).toString());
            this.sheets[index].mergeCells("O"+(31+page*44).toString()+" : "+"O"+(32+page*44).toString());
            this.sheets[index].mergeCells("P"+(31+page*44).toString()+" : "+"P"+(32+page*44).toString());
            this.sheets[index].mergeCells("Q"+(31+page*44).toString()+" : "+"Q"+(32+page*44).toString());

            this.sheets[index].mergeCells("A"+(33+page*44).toString()+" : "+"A"+(34+page*44).toString());
            this.sheets[index].mergeCells("B"+(33+page*44).toString()+" : "+"B"+(34+page*44).toString());
            this.sheets[index].mergeCells("F"+(33+page*44).toString()+" : "+"F"+(34+page*44).toString());
            this.sheets[index].mergeCells("G"+(33+page*44).toString()+" : "+"H"+(34+page*44).toString());
            this.sheets[index].mergeCells("I"+(33+page*44).toString()+" : "+"I"+(34+page*44).toString());
            this.sheets[index].mergeCells("J"+(33+page*44).toString()+" : "+"J"+(34+page*44).toString());
            this.sheets[index].mergeCells("K"+(33+page*44).toString()+" : "+"K"+(34+page*44).toString());
            this.sheets[index].mergeCells("L"+(33+page*44).toString()+" : "+"M"+(34+page*44).toString());
            this.sheets[index].mergeCells("N"+(33+page*44).toString()+" : "+"N"+(34+page*44).toString());
            this.sheets[index].mergeCells("O"+(33+page*44).toString()+" : "+"O"+(34+page*44).toString());
            this.sheets[index].mergeCells("P"+(33+page*44).toString()+" : "+"P"+(34+page*44).toString());
            this.sheets[index].mergeCells("Q"+(33+page*44).toString()+" : "+"Q"+(34+page*44).toString());

            this.sheets[index].mergeCells("A"+(35+page*44).toString()+" : "+"A"+(36+page*44).toString());
            this.sheets[index].mergeCells("B"+(35+page*44).toString()+" : "+"B"+(36+page*44).toString());
            this.sheets[index].mergeCells("F"+(35+page*44).toString()+" : "+"F"+(36+page*44).toString());
            this.sheets[index].mergeCells("G"+(35+page*44).toString()+" : "+"H"+(36+page*44).toString());
            this.sheets[index].mergeCells("I"+(35+page*44).toString()+" : "+"I"+(36+page*44).toString());
            this.sheets[index].mergeCells("J"+(35+page*44).toString()+" : "+"J"+(36+page*44).toString());
            this.sheets[index].mergeCells("K"+(35+page*44).toString()+" : "+"K"+(36+page*44).toString());
            this.sheets[index].mergeCells("L"+(35+page*44).toString()+" : "+"M"+(36+page*44).toString());
            this.sheets[index].mergeCells("N"+(35+page*44).toString()+" : "+"N"+(36+page*44).toString());
            this.sheets[index].mergeCells("O"+(35+page*44).toString()+" : "+"O"+(36+page*44).toString());
            this.sheets[index].mergeCells("P"+(35+page*44).toString()+" : "+"P"+(36+page*44).toString());
            this.sheets[index].mergeCells("Q"+(35+page*44).toString()+" : "+"Q"+(36+page*44).toString());

            this.sheets[index].mergeCells("A"+(37+page*44).toString()+" : "+"A"+(38+page*44).toString());
            this.sheets[index].mergeCells("B"+(37+page*44).toString()+" : "+"B"+(38+page*44).toString());
            this.sheets[index].mergeCells("F"+(37+page*44).toString()+" : "+"F"+(38+page*44).toString());
            this.sheets[index].mergeCells("G"+(37+page*44).toString()+" : "+"H"+(38+page*44).toString());
            this.sheets[index].mergeCells("I"+(37+page*44).toString()+" : "+"I"+(38+page*44).toString());
            this.sheets[index].mergeCells("J"+(37+page*44).toString()+" : "+"J"+(38+page*44).toString());
            this.sheets[index].mergeCells("K"+(37+page*44).toString()+" : "+"K"+(38+page*44).toString());
            this.sheets[index].mergeCells("L"+(37+page*44).toString()+" : "+"M"+(38+page*44).toString());
            this.sheets[index].mergeCells("N"+(37+page*44).toString()+" : "+"N"+(38+page*44).toString());
            this.sheets[index].mergeCells("O"+(37+page*44).toString()+" : "+"O"+(38+page*44).toString());
            this.sheets[index].mergeCells("P"+(37+page*44).toString()+" : "+"P"+(38+page*44).toString());
            this.sheets[index].mergeCells("Q"+(37+page*44).toString()+" : "+"Q"+(38+page*44).toString());

            this.sheets[index].mergeCells("A"+(39+page*44).toString()+" : "+"A"+(40+page*44).toString());
            this.sheets[index].mergeCells("B"+(39+page*44).toString()+" : "+"B"+(40+page*44).toString());
            this.sheets[index].mergeCells("F"+(39+page*44).toString()+" : "+"F"+(40+page*44).toString());
            this.sheets[index].mergeCells("G"+(39+page*44).toString()+" : "+"H"+(40+page*44).toString());
            this.sheets[index].mergeCells("I"+(39+page*44).toString()+" : "+"I"+(40+page*44).toString());
            this.sheets[index].mergeCells("J"+(39+page*44).toString()+" : "+"J"+(40+page*44).toString());
            this.sheets[index].mergeCells("K"+(39+page*44).toString()+" : "+"K"+(40+page*44).toString());
            this.sheets[index].mergeCells("L"+(39+page*44).toString()+" : "+"M"+(40+page*44).toString());
            this.sheets[index].mergeCells("N"+(39+page*44).toString()+" : "+"N"+(40+page*44).toString());
            this.sheets[index].mergeCells("O"+(39+page*44).toString()+" : "+"O"+(40+page*44).toString());
            this.sheets[index].mergeCells("P"+(39+page*44).toString()+" : "+"P"+(40+page*44).toString());
            this.sheets[index].mergeCells("Q"+(39+page*44).toString()+" : "+"Q"+(40+page*44).toString());

            this.sheets[index].mergeCells("A"+(41+page*44).toString()+" : "+"A"+(42+page*44).toString());
            this.sheets[index].mergeCells("B"+(41+page*44).toString()+" : "+"B"+(42+page*44).toString());
            this.sheets[index].mergeCells("F"+(41+page*44).toString()+" : "+"F"+(42+page*44).toString());
            this.sheets[index].mergeCells("G"+(41+page*44).toString()+" : "+"H"+(42+page*44).toString());
            this.sheets[index].mergeCells("I"+(41+page*44).toString()+" : "+"I"+(42+page*44).toString());
            this.sheets[index].mergeCells("J"+(41+page*44).toString()+" : "+"J"+(42+page*44).toString());
            this.sheets[index].mergeCells("K"+(41+page*44).toString()+" : "+"K"+(42+page*44).toString());
            this.sheets[index].mergeCells("L"+(41+page*44).toString()+" : "+"M"+(42+page*44).toString());
            this.sheets[index].mergeCells("N"+(41+page*44).toString()+" : "+"N"+(42+page*44).toString());
            this.sheets[index].mergeCells("O"+(41+page*44).toString()+" : "+"O"+(42+page*44).toString());
            this.sheets[index].mergeCells("P"+(41+page*44).toString()+" : "+"P"+(42+page*44).toString());
            this.sheets[index].mergeCells("Q"+(41+page*44).toString()+" : "+"Q"+(42+page*44).toString());
        },
        setSheetSize(index, page) {      
            this.sheets[index].getRow(11+page*44).height = 15.8;
            this.sheets[index].getRow(12+page*44).height = 15.8;
            this.sheets[index].getRow(13+page*44).height = 15.8;            
            this.sheets[index].getRow(14+page*44).height = 15.8;
            this.sheets[index].getRow(15+page*44).height = 15.8;
            this.sheets[index].getRow(16+page*44).height = 15.8;
            this.sheets[index].getRow(17+page*44).height = 15.8;
            this.sheets[index].getRow(18+page*44).height = 15.8;
            this.sheets[index].getRow(19+page*44).height = 15.8;
            this.sheets[index].getRow(20+page*44).height = 15.8;
            this.sheets[index].getRow(21+page*44).height = 15.8;
            this.sheets[index].getRow(22+page*44).height = 15.8;
            this.sheets[index].getRow(23+page*44).height = 15.8;
            this.sheets[index].getRow(24+page*44).height = 15.8;
            this.sheets[index].getRow(25+page*44).height = 15.8;
            this.sheets[index].getRow(26+page*44).height = 15.8;
            this.sheets[index].getRow(27+page*44).height = 15.8;
            this.sheets[index].getRow(28+page*44).height = 15.8;
            this.sheets[index].getRow(29+page*44).height = 15.8;
            this.sheets[index].getRow(30+page*44).height = 15.8;
            this.sheets[index].getRow(31+page*44).height = 15.8;
            this.sheets[index].getRow(32+page*44).height = 15.8;
            this.sheets[index].getRow(33+page*44).height = 15.8;
            this.sheets[index].getRow(34+page*44).height = 15.8;
            this.sheets[index].getRow(35+page*44).height = 15.8;
            this.sheets[index].getRow(36+page*44).height = 15.8;
            this.sheets[index].getRow(37+page*44).height = 15.8;
            this.sheets[index].getRow(38+page*44).height = 15.8;
            this.sheets[index].getRow(39+page*44).height = 15.8;
            this.sheets[index].getRow(40+page*44).height = 15.8;
            this.sheets[index].getRow(41+page*44).height = 15.8;
            this.sheets[index].getRow(42+page*44).height = 15.8;
        },
        setSheetData(index, page, length) {          
            var MalgunGothic = "Malgun Gothic";

            this.setCell(index, "D"+(2+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(3, 1), MalgunGothic, 12);
            this.setCell(index, "J"+(2+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(9, 1), MalgunGothic, 12);

            this.setCell(index, "O"+(2+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(14, 1), MalgunGothic, 12);
            this.setCell(index, "O"+(3+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(14, 2), MalgunGothic, 12);

            this.setCell(index, "D"+(3+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(3, 2), MalgunGothic, 12);
            this.setCell(index, "J"+(3+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(9, 2), MalgunGothic, 12);

            for(let row=11; row<length+11-1; row+=2) {
                
                this.setCell(index, "A"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(0, (row+page*32) - 1), MalgunGothic, 12);
                this.setCell(index, "B"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(1, (row+page*32) - 1), MalgunGothic, 12);
                this.setCell(index, "C"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(2, (row+page*32) - 1), MalgunGothic, 12);
                this.setCell(index, "D"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(3, (row+page*32) - 1), MalgunGothic, 12);
                this.setCell(index, "E"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(4, (row+page*32) - 1), MalgunGothic, 12);
                this.setCell(index, "F"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(5, (row+page*32) - 1), MalgunGothic, 12);
                this.setCell(index, "G"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(6, (row+page*32) - 1), MalgunGothic, 12);
                this.setCell(index, "I"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(8, (row+page*32) - 1), MalgunGothic, 12);
                this.setCell(index, "J"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(9, (row+page*32) - 1), MalgunGothic, 12);
                
                
                //비행목적 텍스트 길이에 따른 폰트 사이즈 수정
                const purposeText = document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(10, (row+page*32) - 1);
                if(purposeText.length>63) {
                    this.setCell(index, "K"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(10, (row+page*32) - 1), MalgunGothic, 6);
                } else if(purposeText.length>30) {
                    this.setCell(index, "K"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(10, (row+page*32) - 1), MalgunGothic, 8);
                } else if(purposeText.length>15) {
                    this.setCell(index, "K"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(10, (row+page*32) - 1), MalgunGothic, 10);
                } else {
                    this.setCell(index, "K"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(10, (row+page*32) - 1), MalgunGothic, 12);
                }

                //교육생 이름 텍스트 길이에 따른 폰트 사이즈 수정                
                const nameText = document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(11, (row+page*32) - 1);
                if(nameText) {
                    if(nameText.length>5) {
                        this.setCell(index, "L"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(11, (row+page*32) - 1), MalgunGothic, 6);
                    } else if(nameText.length>4) {
                        this.setCell(index, "L"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(11, (row+page*32) - 1), MalgunGothic, 8);
                    } else if(nameText.length>3) {
                        this.setCell(index, "L"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(11, (row+page*32) - 1), MalgunGothic, 10);
                    } else {
                        this.setCell(index, "L"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(11, (row+page*32) - 1), MalgunGothic, 12);
                    }
                }

                //지도조종자 이름 텍스트 길이에 따른 폰트 사이즈 수정                
                const nameText2 = document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(14, (row+page*32) - 1);
                if(nameText2) {
                    if(nameText2.length>5) {
                        this.setCell(index, "O"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(14, (row+page*32) - 1), MalgunGothic, 6);
                    } else if(nameText2.length>4) {
                        this.setCell(index, "O"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(14, (row+page*32) - 1), MalgunGothic, 8);
                    } else if(nameText2.length>3) {
                        this.setCell(index, "O"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(14, (row+page*32) - 1), MalgunGothic, 10);
                    } else {
                        this.setCell(index, "O"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(14, (row+page*32) - 1), MalgunGothic, 12);
                    }
                }
                
                this.setCell(index, "P"+(row+page*44).toString(), document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(15, (row+page*32) - 1), MalgunGothic, 12);
                this.setFormula(index, "J"+(row+page*44).toString(), '=ROUND(SUM(F'+(row+page*44)+':I'+(row+page*44)+'),1)', MalgunGothic, 12);
                
            }
        },
        setDrawBorder(index, page) {
            this.drawBorderLine(index, "A"+(11+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(11+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(11+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(12+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(11+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(12+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(11+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(12+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(11+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(11+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(11+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(11+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(11+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(11+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(11+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(11+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(11+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(11+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "A"+(13+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(13+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(13+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(14+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(13+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(14+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(13+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(14+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(13+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(13+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(13+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(13+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(13+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(13+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(13+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(13+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(13+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(13+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "A"+(15+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(15+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(15+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(16+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(15+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(16+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(15+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(16+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(15+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(15+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(15+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(15+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(15+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(15+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(15+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(15+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(15+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(15+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "A"+(17+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(17+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(17+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(18+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(17+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(18+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(17+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(18+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(17+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(17+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(17+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(17+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(17+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(17+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(17+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(17+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(17+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(17+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "A"+(19+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(19+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(19+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(20+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(19+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(20+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(19+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(20+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(19+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(19+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(19+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(19+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(19+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(19+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(19+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(19+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(19+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(19+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "A"+(21+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(21+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(21+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(22+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(21+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(22+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(21+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(22+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(21+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(21+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(21+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(21+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(21+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(21+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(21+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(21+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(21+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(21+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "A"+(23+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(23+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(23+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(24+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(23+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(24+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(23+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(24+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(23+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(23+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(23+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(23+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(23+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(23+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(23+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(23+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(23+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(23+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "A"+(25+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(25+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(25+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(26+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(25+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(26+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(25+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(26+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(25+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(25+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(25+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(25+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(25+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(25+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(25+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(25+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(25+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(25+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "A"+(27+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(27+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(27+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(28+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(27+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(28+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(27+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(28+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(27+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(27+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(27+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(27+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(27+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(27+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(27+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(27+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(27+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(27+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "A"+(29+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(29+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(29+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(30+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(29+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(30+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(29+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(30+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(29+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(29+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(29+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(29+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(29+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(29+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(29+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(29+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(29+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(29+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "A"+(31+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(31+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(31+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(32+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(31+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(32+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(31+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(32+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(31+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(31+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(31+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(31+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(31+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(31+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(31+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(31+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(31+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(31+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "A"+(33+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(33+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(33+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(34+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(33+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(34+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(33+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(34+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(33+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(33+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(33+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(33+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(33+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(33+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(33+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(33+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(33+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(33+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "A"+(35+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(35+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(35+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(36+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(35+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(36+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(35+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(36+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(35+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(35+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(35+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(35+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(35+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(35+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(35+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(35+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(35+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(35+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "A"+(37+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(37+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(37+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(38+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(37+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(38+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(37+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(38+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(37+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(37+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(37+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(37+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(37+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(37+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(37+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(37+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(37+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(37+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "A"+(39+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(39+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(39+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(40+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(39+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(40+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(39+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(40+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(39+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(39+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(39+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(39+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(39+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(39+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(39+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(39+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(39+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(39+page*44).toString(), this.constants.ALL_LINE);

            this.drawBorderLine(index, "A"+(41+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "B"+(41+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(41+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "C"+(42+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(41+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "D"+(42+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(41+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "E"+(42+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "F"+(41+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "G"+(41+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "I"+(41+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "J"+(41+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "K"+(41+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "L"+(41+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "N"+(41+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "O"+(41+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "P"+(41+page*44).toString(), this.constants.ALL_LINE);
            this.drawBorderLine(index, "Q"+(41+page*44).toString(), this.constants.ALL_LINE);
        },
        setSheetData_footer(index, page) {            
            var MalgunGothic = "Malgun Gothic";
            const pageNum = this.logBookInfo.flightDate.replace(/\./g, '-') + '-' + (page+1).toString();

            this.setCell(index, "A"+(43+page*44).toString(), pageNum, MalgunGothic, 12);
        },
        setSheetSize_footer(index, page) {
            this.sheets[index].getColumn(1+page*44).width = 10.63 + 0.63;
            this.sheets[index].getColumn(2+page*44).width = 11.31 + 0.63;
            this.sheets[index].getColumn(3+page*44).width = 10.13 + 0.63;
            this.sheets[index].getColumn(4+page*44).width = 9.38 + 0.63;
            this.sheets[index].getColumn(5+page*44).width = 8.38 + 0.63;
            this.sheets[index].getColumn(6+page*44).width = 8.63 + 0.63;
            this.sheets[index].getColumn(7+page*44).width = 4.25 + 0.63;
            this.sheets[index].getColumn(8+page*44).width = 4.25 + 0.63;
            this.sheets[index].getColumn(9+page*44).width = 9 + 0.63;
            this.sheets[index].getColumn(10+page*44).width = 9 + 0.63;
            this.sheets[index].getColumn(11+page*44).width = 33.13 + 0.63;
            this.sheets[index].getColumn(12+page*44).width = 2.5 + 0.63;
            this.sheets[index].getColumn(13+page*44).width = 4.63 + 0.63;
            this.sheets[index].getColumn(14+page*44).width = 8.38 + 0.63;
            this.sheets[index].getColumn(15+page*44).width = 8.38 + 0.63;
            this.sheets[index].getColumn(16+page*44).width = 11.13 + 0.63;
            this.sheets[index].getColumn(17+page*44).width = 9 + 0.63;

            this.sheets[index].getRow(43+page*44).height = 15.5;
            this.sheets[index].getRow(44+page*44).height = 15.5;
        },
        setRow(
            index,
            row,
            fontName,
            fontSize,
            wAliment = "center",
            bBold = false,
        ) {
            let logBookList = [];

            for (let col = 0; col < this.pageMaxCol; col++) {
                logBookList.push(document.getElementById('spreadsheet').jexcel[index].getValueFromCoords(col, row - 1));
            }

            this.sheets[index].getRow(row).values = logBookList;

            this.sheets[index].getRow(row).alignment = {
                vertical: "middle",
                horizontal: wAliment,
                wrapText: true,
            };
            this.sheets[index].getRow(row).font = {
                name: fontName,
                size: fontSize,
                bold: bBold,
            };
        },        
        setFormula(
            index,
            cellName,
            msg,
            fontName,
            fontSize,
            wAliment = "center",
            bBold = false
            ) {
                this.sheets[index].getCell(cellName).value = {
                    formula: msg
                }
                this.sheets[index].getCell(cellName).alignment = {
                    vertical: "middle",
                    horizontal: wAliment,
                    wrapText: true,
                };
                this.sheets[index].getCell(cellName).font = {
                    name: fontName,
                    size: fontSize,
                    bold: bBold,
                };
        },
        padZero(num) {
            return num.toString().padStart(2,'0');
        }        
    }
}
</script>